import React from "react";

const LocationCard = ({
  name,
  description,
  address,
  city,
  state,
  zipCode,
  units,
  yearBuilt,
  buildingSize,
  ownerFirstName,
  ownerLastName,
  ownerCompany,
  ownerEmail,
  ownerDirectLine,
  ownerMobile,
}) => {
  const typeColors = {
    park: "bg-green-100 text-green-800",
    landmark: "bg-blue-100 text-blue-800",
    attraction: "bg-purple-100 text-purple-800",
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
      <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
      <p className="text-sm text-gray-600">{description}</p>
      <p className="text-sm text-gray-600">
        <strong>Address:</strong> {address}
      </p>
      <p className="text-sm text-gray-600">
        <strong>City:</strong> {city}
      </p>
      <p className="text-sm text-gray-600">
        <strong>State:</strong> {state}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Zip Code:</strong> {zipCode}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Units:</strong> {units}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Year Built:</strong> {yearBuilt}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Building Size:</strong>{" "}
        {buildingSize ? `${buildingSize} sq ft` : "N/A"}
      </p>

      {/* Owner Details */}
      <h4 className="mt-4 font-semibold text-gray-700">Owner Details:</h4>
      <p className="text-sm text-gray-600">
        <strong>Name:</strong> {ownerFirstName} {ownerLastName}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Company:</strong> {ownerCompany}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Email:</strong>{" "}
        <a
          href={`mailto:${ownerEmail}`}
          className="text-blue-500 hover:underline"
        >
          {ownerEmail}
        </a>
      </p>
      <p className="text-sm text-gray-600">
        <strong>Direct Line:</strong> {ownerDirectLine}
      </p>
      <p className="text-sm text-gray-600">
        <strong>Mobile:</strong> {ownerMobile}
      </p>

      <span
        className={`inline-block mt-2 px-2 py-1 ${typeColors[description]} text-xs font-semibold rounded-full`}
      >
        {/* {description.charAt(0).toUpperCase() + description.slice(1)} */}
      </span>
    </div>
  );
};

export default LocationCard;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Layout from "./components/Layout/Layout";
import Home from "./components/Pages/Home";
import ExploreMap from "./components/ExploreMap/ExploreMap";
import About from "./components/Pages/About";
import Login from "./components/Pages/Login";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginVerification from "./components/LoginVerification/LoginVerification";
import { LoadScript } from "@react-google-maps/api";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <LoadScript googleMapsApiKey="AIzaSyDXoHM-QB5UcWKjqtr49TVGV8AekNx_qVE">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/explore"
                element={
                  // <PrivateRoute>
                  <ExploreMap />
                  // </PrivateRoute>
                }
              />
              <Route
                path="/login-verification"
                element={<LoginVerification />}
              />
              <Route path="/about" element={<About />} />
            </Routes>
          </LoadScript>
        </Layout>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import LocationCard from "../LocationCard/LocationCard";
import axios from "axios"; // Import axios to make API requests

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const defaultCenter = { lat: 31.9686, lng: -99.9018 }; // Default center for Texas
const defaultZoom = 8; // Default zoom level for a wider view of Texas

const ExploreMap = () => {
  const [locations, setLocations] = useState([]); // State for location data fetched from API
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [map, setMap] = useState(null); // Store map instance to manipulate bounds
  const [originalState, setOriginalState] = useState({
    center: defaultCenter,
    zoom: defaultZoom,
  }); // Store original map state (center and zoom)
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions
  const [showSuggestions, setShowSuggestions] = useState(true); // Toggle for suggestions dropdown

  const iconUrls = {
    landmark: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png", // Icon for landmarks
  };

  // Fetch locations from the backend API when the component mounts
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          "https://map.realtnexus.com/api/v1/ghl/get-property-data"
        );
        console.log(response.data);
        setLocations(response.data);
        setIsLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching locations:", error);
        setIsLoading(false); // Set loading to false even if there is an error
      }
    };

    fetchLocations();
  }, []); // Empty dependency array to ensure it runs only once

  // Filter landmarks from the locations dataset based on search term
  useEffect(() => {
    const filtered = locations.filter((location) => {
      return location.propertyName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
    setFilteredLocations(filtered);
    setSuggestions(filtered); // Update suggestions
  }, [searchTerm, locations]);

  // Automatically adjust map bounds to fit all markers
  useEffect(() => {
    if (map && filteredLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredLocations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.latitude, location.longitude)
        );
      });
      map.fitBounds(bounds); // Fit the map to the bounds
    }
  }, [map, filteredLocations]);

  // Zoom and center map when a marker is clicked
  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    if (map) {
      setOriginalState({
        center: map.getCenter().toJSON(), // Store current map center
        zoom: map.getZoom(), // Store current zoom level
      });
      map.setCenter({
        lat: location.latitude,
        lng: location.longitude,
      }); // Center the map on the clicked marker
      map.setZoom(14); // Zoom in (adjust zoom level as needed)
    }
  };

  // Revert map to original state when InfoWindow is closed
  const handleCloseInfoWindow = () => {
    setSelectedLocation(null);
    if (map) {
      map.setCenter(originalState.center); // Reset map center
      map.setZoom(originalState.zoom); // Reset zoom level
    }
  };

  // Handle map load event
  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance); // Store map instance
    setIsLoading(false); // Map is loaded
  };

  // Handle suggestion click
  const handleSuggestionClick = (location) => {
    setSelectedLocation(location);
    if (map) {
      setOriginalState({
        center: map.getCenter().toJSON(), // Store current map center
        zoom: map.getZoom(), // Store current zoom level
      });
      map.setCenter({
        lat: location.latitude,
        lng: location.longitude,
      }); // Center the map on the clicked suggestion
      map.setZoom(14); // Zoom in (adjust zoom level as needed)
    }
  };

  return (
    <div className="relative">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        Explore Landmarks
      </h1>
      <div className="mb-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search landmarks"
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="fas fa-search absolute left-3 top-3 text-gray-400"></i>

          <button
            className="absolute right-3 top-3 bg-blue-400 px-2 py-1 text-xs rounded"
            onClick={() => setShowSuggestions(!showSuggestions)}
          >
            {showSuggestions ? "Hide" : "Show"} Suggestions
          </button>

          {showSuggestions && searchTerm && suggestions.length > 0 && (
            <div className="absolute bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg max-h-60 overflow-auto z-10">
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion._id}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.propertyName}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={defaultZoom} // Default zoom level
            center={defaultCenter}
            onLoad={handleMapLoad} // Store map instance and update loading state
          >
            {filteredLocations.map((location) => (
              <Marker
                key={location._id} // Use _id from the new structure
                position={{
                  lat: location.latitude,
                  lng: location.longitude,
                }}
                icon={iconUrls.landmark}
                onClick={() => handleMarkerClick(location)} // Set selected location and zoom in on marker click
              />
            ))}

            {selectedLocation && (
              <InfoWindow
                position={{
                  lat: selectedLocation.latitude,
                  lng: selectedLocation.longitude,
                }}
                onCloseClick={handleCloseInfoWindow} // Revert to original zoom and center when closed
              >
                <div>
                  <h3 className="font-bold">{selectedLocation.propertyName}</h3>
                  <p>{selectedLocation.buildingStatus}</p>
                  <p>{selectedLocation.address}</p>
                  <p>Units: {selectedLocation.units}</p>
                  <p>Year Built: {selectedLocation.yearBuilt}</p>
                  <p>
                    Building Size:{" "}
                    {selectedLocation.buildingSF
                      ? `${selectedLocation.buildingSF} sq ft`
                      : "N/A"}
                  </p>
                  <p>
                    Owner: {selectedLocation.ownerFirstName}{" "}
                    {selectedLocation.ownerLastName} (
                    {selectedLocation.ownerCompany})
                  </p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      )}

      {/* Render LocationCard only for the selected location */}
      {selectedLocation && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
          <LocationCard
            key={selectedLocation._id}
            name={selectedLocation.propertyName}
            address={selectedLocation.address}
            city={selectedLocation.city}
            state={selectedLocation.state}
            zipCode={selectedLocation.zipCode}
            units={selectedLocation.units}
            yearBuilt={selectedLocation.yearBuilt}
            buildingSize={selectedLocation.buildingSF}
            ownerFirstName={selectedLocation.ownerFirstName}
            ownerLastName={selectedLocation.ownerLastName}
            ownerCompany={selectedLocation.ownerCompany}
            ownerEmail={selectedLocation.ownerEmail}
            ownerDirectLine={selectedLocation.ownerDirectLine}
            ownerMobile={selectedLocation.ownerMobile}
          />
        </div>
      )}
    </div>
  );
};

export default ExploreMap;
